import axios from '@/plugins/axios'

/**
 * Get invoices
 */
const getInvoices = async ({
  paymentStatus = null,
  page = 1
}) => {
  try {
    const params = {
      page: page
    }
    if (paymentStatus) params.payment_status = paymentStatus
    const response = await axios.get('admin/invoices', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update invoice payment status
 */
const updateInvoicePaymentStatus = async (id, {
  status
}) => {
  try {
    const response = await axios.post(`admin/invoices/${id}/payment-status`, {
      _method: 'PUT',
      status: status
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getInvoices,
  updateInvoicePaymentStatus
}
